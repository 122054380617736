@font-face {
    font-family: "GoldmanLight";
    src: local("GoldmanLight"),
    url('Fonts/IRANSansLight.ttf') format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "GoldmanBold";
    src: local("GoldmanBold"),
    url('Fonts/IRANSansBold.ttf') format("truetype");
    font-weight: bold;
}

*,
html,
body {
    font-family: GoldmanLight;
}

h1,
h2,
h3,
h4 {
    font-family: GoldmanBold;
}

body {
    overflow-x: hidden;
/*  background: #2860fc no-repeat !important;
  background: linear-gradient(180deg,#2860fc,#05f2f2) no-repeat !important;*/
}

.center {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.vertical-center {
    min-height: 100%; /* Fallback for browsers do NOT support vh unit */
    min-height: 91vh; /* These two lines are counted as one :-)       */
    display: flex;
    align-items: center;
}

.recharts-wrapper{
    direction: ltr!important;
    text-align:left!important;
}
.recharts-responsive-container{
    direction: ltr!important;
    text-align:left!important;
}

.MuiDrawer-paper {
    background-color: #2860fc !important
}

/* Responsive navigation menu (for mobile devices) */
@media screen and (max-width: 990px) {
    .center {
        text-align: center;
        display: block;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100%;
    }


}

* {
  /*  direction: rtl;*/
    /*  text-align: right;*/
}

.form-check-inline .form-check-input {
    margin-left: 0.3125rem !important;
    margin-right: 0 !important;
}

.dropdown-toggle::after {
    margin-left: 0 !important;
    margin-right: 0.255em;
}

.spinner {
    float: right;
    margin-left: 10px;
}

.close {
    left: 0;
    position: absolute;
}

.operation-button {
    margin-left: 5px;
}

.search-item {
    float: right !important;
    margin: 10px 0 10px 5px !important;
}

.export-button {
    margin-right: 0 !important;
    margin-left: 10px;
}

.icon {
    margin: 20px 20px 20px 5px;
}

.icon:first-child {
    margin-right: 0;
}

.Image-controls-3,
.Image-sliderContainer-4 {
    direction: ltr;
}

.page-item:first-child .page-link {
    border-radius: 0 0.25rem 0.25rem 0 !important;
    margin-left: -1px !important;
}

.page-item:last-child .page-link {
    border-radius: 0.25rem 0 0 0.25rem !important;
}

.form-check-input {
    margin-left: unset !important;
}

.form-check-label[for*="checkbox"] {
    margin-right: 1.25rem;
}

.form-check-inline {
    margin-right: 0 !important;
    margin-left: 0.75rem;
}

.react-tags__suggestions {
    left: unset;
    right: 0;
}

.react-tags__selected-tag:after {
    margin-left: 0 !important;
    margin-right: 8px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.25rem 0.25rem 0 !important;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
    border-radius: 0.25rem 0 0 0.25rem !important;
}
